import { Tabs } from '@mantine/core';
import { hideFromTracking } from '@uag/react-core';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { DetailUserModel } from 'api/v3/models';
import { ProfileActivity } from 'profile/activity/ProfileActivity';
import { Profile } from 'profile/content/Profile';

type ProfileTabProps = {
    user: DetailUserModel;
    className?: string;
};

enum TABS {
    PROFILE = 'profile',
    ACTIVITY = 'activity',
    ROOT = ''
}

export const ProfileTab = ({ user, className }: ProfileTabProps) => {
    const { t } = useTranslation();
    const { tab } = useParams();
    const navigate = useNavigate();

    if (tab && !Object.values(TABS).toString().includes(tab)) {
        return <Navigate to="/notFound" />;
    }

    return (
        <div className={clsx(className)}>
            <h3 className="text-4xl mb-8 hidden sm:block" {...hideFromTracking}>
                {user.displayName}
            </h3>
            <Tabs
                classNames={{
                    list: 'border-b border-b-border-disabled before:border-0',
                    tab: 'text-base px-2 pb-4 pt-0 hover:border-b-border-light data-[active]:border-b-border-accent',
                    panel: 'pt-6'
                }}
                defaultValue="profile"
                value={tab}
                onChange={(value) => navigate(`/${value}`)}
            >
                <Tabs.List>
                    <Tabs.Tab data-testid="profileTab" value={TABS.PROFILE}>
                        {t('profile')}
                    </Tabs.Tab>
                    <Tabs.Tab data-testid="activityTab" value={TABS.ACTIVITY}>
                        {t('activity')}
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={TABS.PROFILE}>
                    <Profile user={user} />
                </Tabs.Panel>
                <Tabs.Panel value={TABS.ACTIVITY}>
                    <ProfileActivity />
                </Tabs.Panel>
            </Tabs>
        </div>
    );
};
